<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col sm="12">
          <b-form-group label-for="clientSearch" description="Search for a client">
            <b-input-group>
              <b-form-input id="clientSearch" ref="search" type="text" v-model="query" @keyup.enter.native="submitSearch"></b-form-input>
              <b-input-group-append>
                <b-button variant="primary" @click="submitSearch"><i class="fa fa-search"></i> Search</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-3" v-if="clients.length > 0">
        <b-col sm="12">
          <b-table :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive="sm" :items="clients" :fields="fields" :current-page="currentPage" :per-page="perPage" @row-clicked="rowClicked">
            <template v-slot:head(actions)></template>
            <template v-slot:cell(createdAt)="data">
              <date :value="data.value" format="MM/DD/YYYY"></date>
            </template>
            <template v-slot:cell(actions)>
              <i class="fa fa-envelope-o ml-2" target="_blank" v-b-tooltip.hover title="Request Intake"></i>
              <!-- <i class="fa fa-stop-circle-o ml-2" v-b-tooltip.hover title="Make Inactive"></i> -->
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" :total-rows="getRowCount(clients)" :per-page="perPage" v-model="currentPage" prev-text="Prev" next-text="Next" hide-goto-end-buttons/>
          </nav>
        </b-col>
      </b-row>
    </b-card-body>
    <add-client-modal @save="addClient"></add-client-modal>
  </b-card>
</template>

<script>
import AddClientModal from '@/components/modals/AddClientModal'
import Date from '@/components/text/Date'
import { mapGetters } from 'vuex'

export default {
  name: 'Clients',
  components: {
    AddClientModal,
    Date
  },
  props: {
    caption: {
      type: String,
      default: 'Clients'
    },
    hover: {
      type: Boolean,
      default: true
    },
    striped: {
      type: Boolean,
      default: true
    },
    bordered: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      clients: [],
      query: null,
      fields: [
        { key: 'primaryContact.firstName', label: 'First Name' },
        { key: 'primaryContact.lastName', label: 'Last Name' },
        { key: 'organization.name', label: 'Organization' },
        { key: 'createdAt', label: 'Date Added' },
        { key: 'primaryContact.mobilePhone.number', label: 'Phone Number' },
        { key: 'status.name', label: 'Status' },
        { key: 'actions', class: "text-right" }
      ],
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      searchTimer: null
    }
  },
  computed: {
    ...mapGetters({
      lastSearch: 'global/lastSearch',
      account: 'global/account'
    })
  },
  methods: {
    submitSearch () {
      this.$router.push({ params: { query: this.query } })
      this.search()
    },
    search () {
      this.$store.dispatch('global/saveLastSearch', this.query)
      if (!this.query) {
        this.clients = []
        return
      }

      this.$api.clients.search({ query: this.query })
        .then((response) => {
          this.clients = response.data
        })
        .catch((err) => {
          this.$snotify.error('Error Searching')
        })
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getRowCount (items) {
      return items.length
    },
    userLink (id) {
      this.$router.push({ name: 'Client', params: { id: id }})
    },
    rowClicked (item) {
      this.userLink(item.id)
    },
    async addClient (newClient) {
      let primaryContactData = newClient.primaryContact
      newClient.organizationId = this.account.organizationId
      try {
        // create client
        const { data: client } = await this.$api.clients.create(newClient)

        // Create primary contact
        primaryContactData.clientId = client.id
        primaryContactData.contactTypeId = 1
        const { data: primaryContact } = await this.$api.contacts.create(primaryContactData)

        this.$snotify.success('Client Created Successfully')
        this.userLink(client.id)
      } catch (err) {
        this.$snotify.error('Error Creating Client')
      }
    }
  },
  created () {
    if (this.$route.params.query) {
      this.query = this.$route.params.query
      this.search()
    }
  },
  mounted () {
    this.$refs.search.focus()
    this.$refs.search.select()
  },
  watch: {
    query () {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.search()
      }, 400)
    },
    '$route.query.add' () {
      if (this.$route.query.add === null) {
        this.$bus.$emit('modal:addClient')
        let query = Object.assign({}, this.$route.query)
        delete query.add
        this.$router.replace({ query })
      }
    }
  },
}
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
